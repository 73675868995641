jQuery(document).ready(function($) {
    
    $(document).on('click', '.site-header .toggle a', function(event){
        event.preventDefault();
        if(!$('.main-navigation').hasClass('active')) {
            $('.main-navigation').addClass('active')
        }
        else {
            $('.main-navigation').removeClass('active');
        }
    });
    
    $(document).on('click', '.main-navigation .nav-top a', function(event){
        event.preventDefault();
        $('.main-navigation').removeClass('active');
    });
    
    $(document).on('click', '.anchors a', function(event){
        event.preventDefault();
        var target = $(this).attr('href');
        if($(target).length > 0) {
            $('html, body').animate({scrollTop: $(target).offset().top - 120});
        }
    });
    
    if($('.hero .hero-slider .item').length > 1) {
        $('.hero .hero-slider').addClass('owl-carousel').owlCarousel({
            items: 1,
            nav: false,
            dots: true,
            smartSpeed: 800,
            loop: true,
            autoplay: true,
        });
    }
    
    $('.room-slider').addClass('owl-carousel').owlCarousel({
        items: 1,
        nav: true,
        navText: ['<svg version="1.2" baseProfile="tiny-ps" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 16" width="17" height="16"><path id="Mask" class="shp0" d="M8 16L6.59 14.59L12.17 9L0 9L0 7L12.17 7L6.59 1.41L8 0L16 8L8 16Z" /></svg>','<svg version="1.2" baseProfile="tiny-ps" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 16" width="17" height="16"><path id="Mask" class="shp0" d="M8 16L6.59 14.59L12.17 9L0 9L0 7L12.17 7L6.59 1.41L8 0L16 8L8 16Z" /></svg>'],
        dots: false,
        smartSpeed: 800,
        loop: true,
        autoplay: true,
    });
        
    if($('.room-listing .images-slider').length > 1) {
        $('.room-listing .images-slider').each(function(){
            $(this).addClass('owl-carousel').owlCarousel({
                items: 1,
                nav: true,
                navText: ['<svg version="1.2" baseProfile="tiny-ps" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 16" width="17" height="16"><path id="Mask" class="shp0" d="M8 16L6.59 14.59L12.17 9L0 9L0 7L12.17 7L6.59 1.41L8 0L16 8L8 16Z" /></svg>','<svg version="1.2" baseProfile="tiny-ps" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 16" width="17" height="16"><path id="Mask" class="shp0" d="M8 16L6.59 14.59L12.17 9L0 9L0 7L12.17 7L6.59 1.41L8 0L16 8L8 16Z" /></svg>'],
                dots: false,
                smartSpeed: 800,
                loop: true,
                autoplay: true,
            });
        });        
    }
    
    $('.offers-slider').addClass('owl-carousel').owlCarousel({
        nav: true,
        navText: ['<svg version="1.2" baseProfile="tiny-ps" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 16" width="17" height="16"><path id="Mask" class="shp0" d="M8 16L6.59 14.59L12.17 9L0 9L0 7L12.17 7L6.59 1.41L8 0L16 8L8 16Z" /></svg>','<svg version="1.2" baseProfile="tiny-ps" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 16" width="17" height="16"><path id="Mask" class="shp0" d="M8 16L6.59 14.59L12.17 9L0 9L0 7L12.17 7L6.59 1.41L8 0L16 8L8 16Z" /></svg>'],
        dots: true,
        smartSpeed: 800,
        margin: 20,
        loop: true,
        autoplay: true,
        responsive:{
            0:{
                items: 1,
            },
            767:{
                items: 3,
            }
        }
    });
    
    if($('.restaurants .images .item').length > 1) {
        $('.restaurants .images').addClass('owl-carousel').owlCarousel({
            items: 1,
            nav: false,
            dots: true,
            smartSpeed: 800,
            loop: true,
            autoplay: true,
        });
    }
    
    if($('.bars .images .item').length > 1) {
        $('.bars .images').addClass('owl-carousel').owlCarousel({
            items: 1,
            nav: false,
            dots: true,
            smartSpeed: 800,
            loop: true,
            autoplay: true,
        });
    }
    
    if($('.full-slider .item').length > 1) {
        $('.full-slider').addClass('owl-carousel').owlCarousel({
            items: 1,
            nav: true,
            navText: ['<svg version="1.2" baseProfile="tiny-ps" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 16" width="17" height="16"><path id="Mask" class="shp0" d="M8 16L6.59 14.59L12.17 9L0 9L0 7L12.17 7L6.59 1.41L8 0L16 8L8 16Z" /></svg>','<svg version="1.2" baseProfile="tiny-ps" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 16" width="17" height="16"><path id="Mask" class="shp0" d="M8 16L6.59 14.59L12.17 9L0 9L0 7L12.17 7L6.59 1.41L8 0L16 8L8 16Z" /></svg>'],
            dots: false,
            smartSpeed: 800,
            loop: true,
            autoplay: true,
        });
    }
    
    $('.testimonials-slider').addClass('owl-carousel').owlCarousel({
        items: 1,
        nav: false,
        dots: true,
        smartSpeed: 800,
        autoplay: true,
        loop: true,
    });
    
    $(document).on('click','.accordions .accordion',function(event){
        event.preventDefault();
        $(this).toggleClass('active');
        $(this).find('.content').stop().slideToggle();
    });
    
    $(window).scroll(function(){
        if($(this).scrollTop() > 2) {
            if(!$('.site-header').hasClass('scrolldown')) {
                $('.site-header').addClass('scrolldown');
            }
        }
        else {
            $('.site-header').removeClass('scrolldown');
        }
        
        if($(".page-template-template-dining").length > 0) {
            var top_of_element = $(".page-template-template-dining #rooftop").offset().top;
            var bottom_of_element = $(".page-template-template-dining .fullheight-slider").offset().top + $(".page-template-template-dining .fullheight-slider").outerHeight();
            var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
            var top_of_screen = $(window).scrollTop();
            
            if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)){
                $('.page-template-template-dining .dining').removeClass('cb-lightgrey').addClass('cb-darkgreen');
                $('.page-template-template-dining .fullheight-slider').removeClass('cb-white').addClass('cb-darkgreen');
            } else {
                $('.page-template-template-dining .dining').removeClass('cb-darkgreen').addClass('cb-lightgrey');
                $('.page-template-template-dining .fullheight-slider').removeClass('cb-darkgreen').addClass('cb-white');
            }
        }
    });
    
    if($('.grid').length > 0) {
        $('.grid').imagesLoaded(function () {     
            console.log('grid images loaded');        
            $('.grid').isotope({
                // options
                itemSelector: '.grid-item',
                //layoutMode: 'fitRows'
            });        
        });
    }
    
    if($('.content-masonry').length > 0) {
        $('.content-masonry').imagesLoaded(function () {                
            console.log('content masonry images loaded');
            $('.content-masonry').isotope({
                // options
                itemSelector: '.content-block',
                //layoutMode: 'fitRows'
            });               
        });
    }
    
    AOS.init({
        duration: 1000,
    });

    var cursor = $('#circle');

    var mouseX = 0, mouseY = 0;
    var xp = 0, yp = 0;
    
    $(document).mousemove(function(e){
        mouseX = e.clientX - cursor.width() / 2;
        mouseY = e.clientY - cursor.height() / 2;
    });
        
    setInterval(function(){
        xp += ((mouseX - xp)/6);
        yp += ((mouseY - yp)/6);
        $("#circle").css({left: xp +'px', top: yp +'px'});
    }, 5);
    
    // $(window).mousemove(function(e) {
    //     cursor.css({
    //         top: e.clientY - cursor.height() / 2,
    //         left: e.clientX - cursor.width() / 2,
    //     });
    // });

    $(window)
        .mouseleave(function() {
            cursor.css({
                opacity: "0"
            });
        })
        .mouseenter(function() {
            cursor.css({
                opacity: "1"
            });
        });

    $(window)
        .mousedown(function() {
            cursor.css({
                transform: "scale(.2)"
            });
        })
        .mouseup(function() {
            cursor.css({
                transform: "scale(1)"
            });
        });
    
    $("a, button")
        .mouseenter(function() {
            cursor.addClass('onHover');
            cursor.css({
                transform: "scale(2)",
                transition: "transform .3s ease"
            });
        })
        .mouseleave(function() {
            cursor.removeClass('onHover');
            cursor.css({
                transform: "scale(1)",   
                transition: "transform .3s ease"             
            });
        });

});